/*I have used a local ttf file to load the font, you can use any font you wish*/
/* @font-face {
    font-family: "";
    src: url("../public/fonts/LouisGeorgeCafe.ttf");
} */

.spotifyWidget {
    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--body-font);
}

.nowPlayingCard {
    flex-shrink: 0;
    border-radius: 22px;
    border: 2px solid #000;
    box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.3);
    align-items: center;
    width: 300px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    transition: all 0.5s ease;
    background-color: rgba(255, 255, 255, 0.801);
}

.nowPlayingCard:hover {
    box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.3);
    transform: translateX(-5px) translateY(-5px);
    background-color: rgba(255, 255, 255, 0.932);
    transition: all 0.5s ease;
}

.nowPlayingCard a {
    color: black;
    text-decoration: none;
}

.nowPlayingCard a:hover {
    color: black;
    text-decoration: underline;
}

.nowPlayingImage img {
    border-radius: 8px;
    border: 1px solid black;
    /* box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.3); */
    transition: all 0.5s ease;
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    margin: 10px;
    margin-top: 15px;
}

.nowPlayingImage .spotifyIcon {
    display: flex;
    padding-left: 5px;
}

#nowPlayingDetails {
    justify-content: center;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 100%;
}

.marquee {
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    width: 100%;
    box-sizing: border-box;
}

/* Animate the marquee content */
.marquee-content {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 10s linear infinite;
}

/* Define the animation */
@keyframes marquee {
    0% {
        /* transform: translate(0, 0); */
        transform: translateX(0%);
    }

    100% {
        /* transform: translate(-100%, 0); */
        transform: translateX(-100%);
    }
}

.nowPlayingTitle {
    flex-shrink: 0;
    color: #000;
    white-space: nowrap;
    text-align: left;
    /* font-size: 20px; */
    font-size: var(--normal-font-size);
    width: 100%;
}

.nowPlayingArtist {
    display: none;
    text-align: left;
    flex-shrink: 0;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    font-size: var(--tiny-font-size);
}

.nowPlayingTime {
    text-align: left;
}

.nowPlayingState {
    display: flex;
    text-align: center;
    padding-right: 10px;
}

.nowPlayingState img {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    filter: invert(100%);
    height: 30px;
}

/* ========== BREAKPOINTS ========== */
/* For large devices */
@media screen and (max-width: 992px) {}

/* For medium devices */
@media screen and (max-width: 768px) {
    .nowPlayingCard {
        width: 200px;
        height: 30px;
    }

    .nowPlayingImage img {
        display: none;
    }

    .nowPlayingImage .spotifyIcon {
        width: 80%;
    }

    .nowPlayingArtist {
        display: none;
    }

    .nowPlayingState {
        display: flex;
        justify-content: flex-end;
        text-align: center;
        padding-right: 4px;
        width: 25px;
    }

    .nowPlayingState .soundbar {
        width: 100%;
        height: 100%;
    }

    .nowPlayingTitle {
        font-size: var(--small-font-size);
        display: flex;
        align-items: center;
        width: 93%;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
    .nowPlayingCard {
        width: 180px;
        height: 30px;
    }

    .nowPlayingImage img {
        display: none;
    }

    .nowPlayingImage .spotifyIcon {
        width: 80%;
    }

    .nowPlayingArtist {
        display: none;
    }

    .nowPlayingState {
        display: flex;
        justify-content: center;
        text-align: center;
        padding-right: 7px;
        width: 15%;
    }

    .nowPlayingTitle {
        font-size: var(--small-font-size);
        display: flex;
        align-items: center;
        width: 93%;
    }
}