/* ========== GOOGLE FONTS ========== */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

/* ========== VARIABLES CSS ========== */
:root {
	--header-height: 3rem;

	/* ========== Colors ========== */
	--hue: 0;
	--sat: 0%;
	--title-color: hsl(var(--hue), var(--sat), 20%);
	--title-color-dark: hsl(var(--hue), var(--sat), 0%);
	--text-color: hsl(var(--hue), var(--sat), 46%);
	--body-color: hsl(var(--hue), var(--sat), 98%);
	--border-color: 2px solid rgba(0, 0, 0, 0.3);
	--container-color: #fff;
	--nav-color: #fff;

	/* ========== Font and typography ========== */
	--body-font: "Poppins", sans-serif;
	--big-font-size: 3.5rem;
	--h1-font-size: 2.25rem;
	--h2-font-size: 1.5rem;
	--h3-font-size: 1.25rem;
	--normal-font-size: 1rem;
	--small-font-size: 0.875rem;
	--smaller-font-size: 0.813rem;
	--tiny-font-size: 0.625rem;

	/* ========== Font Weight ========== */
	--font-normal: 400;
	--font-medium: 500;
	--font-semi-bold: 600;

	/* ========== Margins Bottom ========== */
	--mb-0-25: 0.25rem;
	--mb-0-5: 0.5rem;
	--mb-0-75: 1rem;
	--mb-1: 1rem;
	--mb-1-5: 1.5rem;
	--mb-2: 2rem;
	--mb-2-5: 2.5rem;
	--mb-3: 3rem;

	/* ========== Z index ========== */
	--z-tooltip: 10;
	--z-fixed: 100;
	--z-modal: 1000;
}

/* Responsive typography */
@media screen and (max-width: 992px) {
	:root {
		--big-font-size: 2.75rem;
		--h1-font-size: 1.5rem;
		--h2-font-size: 1.25rem;
		--h3-font-size: 1rem;
		--normal-font-size: 0.938rem;
		--small-font-size: 0.813rem;
		--smaller-font-size: 0.75rem;
	}
}

/* ========== BASE ========== */
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
}

body,
button,
input,
textarea {
	font-family: var(--body-font);
	font-size: var(--normal-font-size);
}

body {
	background-color: var(--body-color);
	color: var(--text-color);
}

/* ========== DARK-MODE CSS ========== */
body.dark-mode {
	--title-color: #fff;
	--text-color: #b6b6b6;
	--body-color: #121212;
	--container-color: #1e1e1e;
	--title-color-dark: rgb(216, 214, 214);
	--border-color: 2px solid #a1a1a1a1;
	--nav-color: #1e1e1e;
}

h1,
h2,
h3 {
	color: var(--title-color);
	font-weight: var(--font-semi-bold);
}

ul {
	list-style: none;
}

a {
	text-decoration: none;
}

button {
	cursor: pointer;
	border: none;
	outline: none;
}

img {
	max-width: 100%;
	height: auto;
}

/* ========== REUSABLE CSS CLASSES ========== */
.section {
	padding: 6rem 0 2rem;
}

.section__title {
	font-size: var(--h1-font-size);
	color: var(--title-color);
}

.section__subtitle {
	display: block;
	font-size: var(--small-font-size);
	margin-bottom: 4rem;
}

.section__title,
.section__subtitle {
	text-align: center;
}

/* ========== LAYOUT ========== */
.container {
	max-width: 968px;
	margin-left: auto;
	margin-right: auto;
}

.grid {
	display: grid;
	gap: 1.5rem;
}

/* ========== BUTTONS ========== */
.button {
	display: inline-block;
	background-color: var(--title-color);
	color: var(--container-color);
	padding: 1.25rem 2rem;
	border-radius: 1rem;
	font-weight: var(--font-medium);
}


.button:hover {
	background-color: var(--title-color-dark);
	scale: 1.01;
	transition: all 0.2s ease;
}

.button:hover svg {
	margin-right: 3px;
	transform: rotate(30deg);
	transition: transform 0.25s cubic-bezier(0.76, 0, 0.24, 1);
}

.button__icon {
	margin-left: var(--mb-0-5);
}

.button--flex {
	display: inline-flex;
	align-items: center;
}

/* ========== BREAKPOINTS ========== */
/* For large devices */
@media screen and (max-width: 992px) {
	.container {
		margin-left: var(--mb-1-5);
		margin-right: var(--mb-1-5);
	}

	.button {
		padding: 1rem 1.75rem;
	}

	.button__icon {
		width: 22px;
		height: 22px;
	}
}

/* For medium devices */
@media screen and (max-width: 768px) {
	body {
		margin: 0 0 var(--header-height) 0;
	}

	.section {
		padding: 2rem 0 4rem;
	}

	.section__subtitle {
		margin-bottom: var(--mb-3);
	}
}

@media screen and (max-width: 576px) {}

/* For small devices */
@media screen and (max-width: 350px) {
	:root {
		--big-font-size: 2.25rem;
	}

	.container {
		margin-left: var(--mb-1);
		margin-right: var(--mb-1);
	}
}