.project__container {
    width: 750px;
}

.project__card {
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    /* padding: 1.5rem 2rem; */
    border-radius: 1.5rem;
    margin-bottom: var(--mb-3);
}

.project__img {

    border-radius: 1.5rem 1.5rem 0 0;
    margin-bottom: var(--mb-1);
}

.project__name {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-0-25);
    padding: 0 2rem 0 2rem;
}

.project__description {
    font-size: var(--small-font-size);
    padding: 0.5rem 2rem 1.5rem 2rem;
}

/* Swiper Class */
.swiper-pagination-bullet {
    background-color: var(--text-color) !important;
}

.swiper-pagination-bullet-active {
    background-color: var(--title-color) !important;
}

.project__button {
    color: var(--title-color);
    font-size: var(--small-font-size);
    display: inline-flex;
    align-items: center;
    column-gap: 0.25rem;
    cursor: pointer;
    padding-top: 0.8rem;
}

.project__button-icon {
    font-size: 1rem;
    transition: 0.3s;
}

.project__button:hover .project__button-icon {
    transform: translateX(00.25rem);
}

/* Bounce Animation */
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-3px);
    }
    60% {
        transform: translateY(-3px);
    }
}

.uil-clock {
    animation: bounce 2s infinite;
}

/* ========== BREAKPOINTS ========== */
/* For large devices */
@media screen and (max-width: 992px) {
	.project__container {
        width: initial;
    }

    /* .project__card {
        padding: 1.25rem 1.5rem;
    } */
}